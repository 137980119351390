import React from "react"
import styled from "styled-components"
import { keyframes } from "styled-components"
import UiLink from "../components/UiLink"
import { Alert, Frame, Modal } from "@react95/core"

const ErrorFullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a;
  color: white;
  z-index: 9999;

  a {
    color: white !important;
  }
`

const makeMeBlink = keyframes`
66% {
  opacity: 0;
}
`

const Blink = styled.span`
  animation: ${makeMeBlink} 1s steps(1) infinite;
`

const H1 = styled.h1`
  background-color: #f1f1f1;
  color: #00a;
  padding: 0 1rem;
  font-size: 1rem;
  display: inline-block;
`

const CenterText = styled.div`
  text-align: center;
`
const NotFoundPage = () => {
  return (
    <ErrorFullScreen>
      <Frame
        w="100%"
        h="100%"
        m={0}
        p="3rem 4rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="none"
        bg="unset"
      >
        <Frame boxShadow="none" bg="unset" maxWidth="40rem">
          <CenterText>
            <H1>404 Page not found</H1>
          </CenterText>

          <p>A fatal exception 0x1337 has occurred.</p>
          <p>The current page wasn't found.</p>

          <br />
          <br />

          <CenterText as="p">
            Please <UiLink to="/">click here to go to homepage</UiLink>
            <Blink> _</Blink>
          </CenterText>
        </Frame>
      </Frame>
    </ErrorFullScreen>
  )
}

export default NotFoundPage
